import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TaskRequestSidenavService} from '../../../core/services/task-request-sidenav.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {StockDataSourceService} from '../../../core/data-sources/stock-data-source.service';
import {ActionService} from '../../../core/services/action.service';
import { toExcelFormat } from 'src/app/helpers/format-position';
import centimetersToMeters from 'src/app/helpers/centimeters-to-meters';

export interface ProductsInterface {
  description: string;
  name: string;
  sku: string;
  type: string;
  unit: string;
  vendorCode: string;
}

export interface GroundPositionsInterface {
  column: string;
  groundId: string;
  groupName: string;
  line: number;
  rfid: string;
  state: string;
  type: string;
  x: number;
  y: number;
}

@Component({
  selector: 'app-stock-inventory-sidenav',
  templateUrl: './stock-inventory-sidenav.component.html',
  styleUrls: ['./stock-inventory-sidenav.component.scss']
})
export class StockInventorySidenavComponent implements OnInit, OnDestroy {

  @Input() drawer;

  openSubscription: Subscription;
  toExcelFormat = toExcelFormat;
  centimetersToMeters = centimetersToMeters;
  isCritical = false;
  usersHighlighted = [];

  isProductToggle = false;
  isSectorToggle = false;
  isPalletToggle = false;
  inventoryForm: FormGroup;

  sectorValue;
  palletInputSubscription$: Subscription;
  productInputSubscription: Subscription;
  resetInputSubscription: Subscription;
  products: ProductsInterface[];
  isProductChosen = false;
  isPalletChosen = false;
  groundPosition: GroundPositionsInterface;
  sectorOptions = [];
  selectedSectors = [];
  palletOptions = [];
  selectOperatorError = false;
  sectorsWithoutProducts = [];
  palletWithoutProduct;

  constructor(private sidenavService: TaskRequestSidenavService,
              private stockService: StockDataSourceService,
              private actionService: ActionService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.openSubscription =  this.drawer.openedStart.subscribe(async () => {
      this.groundPosition = await this.stockService.getGroundPosition(true);
      this.getSectors(this.groundPosition);
    });
    this.resetInputSubscription = this.inventoryForm.controls.product.valueChanges.subscribe(() => {
      if (this.isProductChosen) {
        this.isProductChosen = false;
        this.inventoryForm.reset();
      }
    });
    this.productInputSubscription = this.inventoryForm.controls.product.valueChanges.pipe(debounceTime(250))
      .subscribe(async (newValue) => {
        this.products = await this.stockService.getSearchedProducts(newValue);
        if (newValue?.sku) {
          this.isProductChosen = true;
        }
      }
    );

    this.resetInputSubscription = this.inventoryForm.controls.pallet.valueChanges.subscribe(() => {
      if (this.isPalletChosen) {
        this.isPalletChosen = false;
        this.inventoryForm.reset();
      }
    });

    this.palletInputSubscription$ = this.inventoryForm.controls.pallet.valueChanges
      .pipe(debounceTime(250))
      .subscribe(async (newValue) => {
        this.palletOptions = await this.stockService.getInventoryPalletOptions(newValue);
        if (newValue?.tagRfid) {
          this.isPalletChosen = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.productInputSubscription.unsubscribe();
    this.resetInputSubscription.unsubscribe();
    this.openSubscription.unsubscribe();
  }

  buildForm() {
    this.inventoryForm = this.formBuilder.group({
      product: [''],
      sector: [''],
      pallet: ['']
    });
  }

  setUsers(users) {
    this.usersHighlighted = users;
  }

  onCriticalChange(isChecked) {
    this.isCritical = isChecked;
  }

  toggleOption(type) {
    switch (type) {
      case "PRODUCT":
        this.isProductToggle = true;
        this.isSectorToggle = false;
        this.isPalletToggle = false;
        break;
      case "SECTOR":
        this.isSectorToggle = true;
        this.isProductToggle = false;
        this.isPalletToggle = false;
        break;
      case "PALLET":
        this.isProductToggle = false;
        this.isSectorToggle = false;
        this.isPalletToggle = true;
        break;
    }
  }

  displayedString(product) {
    if (product) {
      return product.name + ' - ' + product.sku;
    } else {
      return null;
    }
  }

  setDisplayedPalletName(pallet) {
    if (pallet) {
      return `Tag RFID: ${pallet.tagRfid} - Posição: ${toExcelFormat(pallet.x, pallet.y)} - Altura: ${ pallet.z >= 0 ? centimetersToMeters(pallet.z) + ' metros' : 'Conflitante'}`
    } else {
      return null;
    }
  }

  onChangeSector(event) {
    this.selectedSectors = event.value;
  }

  getSectors(gp) {
    const options = [];
    for (const item of gp) {
      if (!options.filter(e => e.name === item.groupName).length && item.groupName !== null) {
        options.push({ name: item.groupName, groupId: item.groupId });
      }
    }
    this.sectorOptions = [...options];
    this.sectorOptions.sort((a, b) => {
      if (a > b) { return 1; }
      if (a < b) { return -1; }
      return 0;
    });
  }

  async inventoryByGroundPosition() {
    const data = {
      type: 'INVENTORY_BY_GROUND_POSITION_GROUP',
      userIds: this.usersHighlighted,
      taskData: {
        groundPositionGroupId: this.selectedSectors
      }
    };
    await this.actionService.inventoryByGroundPositionGroup(data);
  }

  async inventoryByProduct() {
    const data = {
      type: 'INVENTORY_BY_PRODUCT',
      userIds: this.usersHighlighted,
      taskData: {
        sku: [this.inventoryForm.controls.product.value.sku]
      }
    };
    await this.actionService.inventoryByProduct(data);
  }

  async inventoryByTagRfid() {
    const data = {
      type: "PRODUCT_INVENTORY_BY_CRATE_TAG_RFID",
      userIds: this.usersHighlighted,
      taskData: {
        tagRfid: this.inventoryForm.controls.pallet.value.tagRfid
      }
    }
    await this.actionService.inventoryBySingleTagRfid(data);
  }

  setOperatorLengthError() {
    this.selectOperatorError = !this.usersHighlighted.length;
  }

  handleGroundPositionWithoutCrates(groupIds) {
    let sectors = [];
    for (const id of groupIds) {
      sectors = this.sectorOptions.filter(e => e.groupId === id);
      if (!this.sectorsWithoutProducts.filter(e => e.groupId === id).length && id !== null) {
        this.sectorsWithoutProducts.push(sectors[0]);
      }
    }
  }

  async submit() {
    try {
      this.setOperatorLengthError();
      if ((this.inventoryForm.controls.product.valid || this.inventoryForm.controls.sector.valid || this.inventoryForm.controls.pallet.valid) && this.usersHighlighted.length) {
        if (this.isSectorToggle) { await this.inventoryByGroundPosition(); }
        if (this.isProductToggle) { await this.inventoryByProduct(); }
        if (this.isPalletToggle) await this.inventoryByTagRfid();
        this.drawer.close();
        this.sidenavService.closeSidenav(true);
        this.isProductToggle = false;
        this.isSectorToggle = false;
        this.isCritical = false;
        this.usersHighlighted = [];
        this.sectorsWithoutProducts = [];
        this.palletWithoutProduct = '';
      }
    } catch (e) {
      if (e.error.type === 'GROUP_POSITION_GROUP_WITHOUT_CRATES') {
        this.handleGroundPositionWithoutCrates(e.error.data);
      }
      if (e.error.type === 'NO_PALLET_WITH_PRODUCT_FOUND') {
        this.palletWithoutProduct = e.error.data;
      }
    }
  }

  close() {
    try {
      this.drawer.close();
      this.sidenavService.closeSidenav(false);
    } finally {
      this.isCritical = false;
      this.isProductToggle = false;
      this.isSectorToggle = false;
      this.sectorValue = null;
      this.usersHighlighted = [];
      this.sectorsWithoutProducts = [];
      this.palletWithoutProduct = '';
    }
  }
}
