import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getCoordinates, toExcelFormat } from '../../helpers/format-position';
import {StockDataSourceService} from '../../core/data-sources/stock-data-source.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { GroundGroupsService } from '../../core/services/ground-groups.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-sidenav-destination',
  templateUrl: './sidenav-destination.component.html',
  styleUrls: ['./sidenav-destination.component.scss']
})
export class SidenavDestinationComponent implements OnInit, OnChanges, OnDestroy {

  @Output() changed = new EventEmitter();

  @Input() groundType;
  @Input() suggestedPosition;
  @Input() selectedPosition: string | null;

  groundPosition;
  groundPositions = [];
  filteredGroundPosition = [];

  groundGroups = [];
  filteredGroundGroups = [];
  groundGroupsSubscription: Subscription;
  groundGroupsEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  form: FormGroup;

  excelToXY = new Map();

  @ViewChild('groundGroupAuto') groundGroupAuto: any;

  constructor(
    private formBuilder: FormBuilder,
    private stockService: StockDataSourceService,
    private groundGroupService: GroundGroupsService) {
    this.form = this.formBuilder.group({
      groundGroup: ['', Validators.required],
      position: ['', Validators.required]
    });

    this.groundGroupsSubscription = this.form.controls.groundGroup.valueChanges.subscribe(data => {
      const {valid} = this.form;
      this.filterGroundGroup(data);
      this.changed.emit({valid,
        data: {sector: data}}
      );
    });
    this.form.controls.position.valueChanges.subscribe(data => {
      this.filterPositionGroup(data);
      let address = null;
      if(this.excelToXY.has(data)){
        const {x,y} = this.excelToXY.get(data);
        address = {x,y};
      }
      //TODO: value of parameter this.form.valid is updated to true after executing valueChanges
      setTimeout(()=> {
        const {valid} = this.form;
        this.changed.emit({valid,
          data: {address}}
        );
      },0);
    });
  }


  private filterGroundGroup(value?) {
    if (typeof value === 'string'){
      const filterValue = value.toLowerCase();
      this.filteredGroundGroups = this.groundGroups.filter(option => option.name.toLowerCase().includes(filterValue));
    } else if (value) {
      const filterValue = value.name.toLowerCase();
      this.filteredGroundGroups = this.groundGroups.filter(option => option.name.toLowerCase().includes(filterValue));
    } else {
      this.filteredGroundGroups = [...this.groundGroups];
    }
  }

  private filterPositionGroup(value: string) {
    if (value){
      const filterValue = value.toLowerCase();
      this.filteredGroundPosition = this.groundPositions.filter(option => option.formatted.toLowerCase().includes(filterValue));
    } else{
      this.filteredGroundPosition = [...this.groundPositions];
    }
  }

  reset() {
    this.form.reset();
  }

  async ngOnInit() {
    await this.getGroundPosition();
    await this.getSectors();
    this.filterGroundGroup();
    if (this.suggestedPosition) {
      this.suggestedPosition = {
        isSuggested: true,
        coordinates: { x: this.suggestedPosition.x, y: this.suggestedPosition.y },
        formatted: toExcelFormat(this.suggestedPosition.x, this.suggestedPosition.y)
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.form.enable();
    if (changes.selectedPosition.currentValue) {
      if (this.selectedPosition) {
        this.groundGroupsEvent.subscribe(res => {
          if (res) {
            const coordinates = getCoordinates(this.selectedPosition);
            coordinates.y--;
            this.groundGroups.forEach(gg => {
              const element = gg.positions.find(el => el.x === coordinates.x && el.y === coordinates.y);
              if (element) {
                this.form.controls.groundGroup.setValue(gg);
                this.form.controls.position.setValue(this.selectedPosition);
                this.changed.emit({valid: this.form.controls.groundGroup.valid, data: {sector: gg, address: this.selectedPosition}});
                this.form.disable();
              }
            });
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.groundGroupsEvent?.unsubscribe();
  }

  async getSectors() {
    this.groundGroupsEvent.next(false);
    this.groundGroups = await this.groundGroupService.getGroundGroups(true);
    if (this.groundType) {
      this.groundGroups = this.groundGroups.filter(gg => gg.type === this.groundType);
    }
    this.groundGroups.sort((a, b) => {
      if (a.name > b.name) { return 1; }
      if (a.name < b.name) { return -1; }
      return 0;
    });
    this.groundGroupsEvent.next(true);
  }

  onChangeSector(event) {
    if (event.isUserInput) {
      this.getAddresses(this.groundPosition, event.source.value);
    }
  }

  groundGroupAutoClosed(event){
    const value = this.form.controls.groundGroup.value;
    const currentGroundGroups = [...this.filteredGroundGroups];
    if (!currentGroundGroups.includes(value)){
      this.form.controls.groundGroup.reset();
      this.form.controls.position.reset();

    }
  }
  groundPositionAutoClosed(event){
    const value = this.form.controls.position.value;
    const currentPositions = [...this.filteredGroundPosition.map(g =>g.formatted)];
    if (!currentPositions.includes(value)){
      this.form.controls.position.reset();
    }
  }
  getAddresses(gp, selectedSector) {
    const options = [];
    for (const item of gp) {
      if (!options.includes(item) && item.groupName === selectedSector.name) {
        const formatted = toExcelFormat(item.x, item.y);
        const coordinates = { x: item.x, y: item.y };
        options.push({ coordinates, formatted });
        this.excelToXY.set(formatted, coordinates);
      }
    }
    this.groundPositions = [...options];
    if (this.suggestedPosition) {
      this.groundPositions.push(this.suggestedPosition);
    }
    this.groundPositions.sort((a, b) => {
      if (a?.isSuggested === true) {
        return -1;
      }
      if (a.coordinates.x === b.coordinates.x) {
        if (a.coordinates.y < b.coordinates.y) {
          return -1;
        }
      } else {
        if (a.coordinates.x === b.coordinates.x) {
          if (a.coordinates.y > b.coordinates.y){
            return 1;
          }
        }
      }
      if (a.coordinates.x < b.coordinates.x) {
        return -1;
      } else {
        if (a.coordinates.x > b.coordinates.x) {
          return 1;
        }
      }
      return 0;
    });
    this.filteredGroundPosition = [...this.groundPositions];
  }
  async getGroundPosition() {
    this.groundPosition = await this.stockService.getGroundPosition(true);
    if (this.groundType){
      this.filteredGroundPosition = this.groundPosition.filter(g => g.type === this.groundType);
    } else{
      this.filteredGroundPosition = [...this.groundPosition];
    }
  }

  displayedGroundGroup(gg) {
    if (gg?.name) {
      return gg.name;
    } else if (gg?.groupName) {
      return gg.groupName;
    } else {
      return null;
    }
  }
}
