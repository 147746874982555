import { Injectable } from '@angular/core';
import {StockDataSourceService} from '../data-sources/stock-data-source.service';
import {Observable, Subject} from 'rxjs';
import { CrateStateEnum } from '../../../models/Crate';

export interface StockFilterParams {
  sku?: string;
  state?: CrateStateEnum;
  section?: string;
  levelName?: string;
  name?: string;
  x?: number;
  y?: number;
  endDate?: number;
  startDate?: number;
  vendorDoc?: string;
  quantity?: number;
}

@Injectable({
  providedIn: 'root'
})
export class StockService {

  private $pageSize: Subject<number> = new Subject<number>();
  private $totalItems: Subject<number> = new Subject<number>();
  private $pageCount: Subject<number> = new Subject<number>();
  private $currentPage: Subject<number> = new Subject<number>();
  private $atLastPage: Subject<boolean> = new Subject<boolean>();
  private $atFirstPage: Subject<boolean> = new Subject<boolean>();

  private $items: Subject<any[]> = new Subject<any[]>();

  private pageSize = 30;

  private pageCount;
  private currentPage;
  private totalItems;
  private items: [] = [];

  constructor(private dataSource: StockDataSourceService) {

  }

  async init(filter: StockFilterParams = null){
    return this.getPage(1, filter);
  }

  async setPageSize(pageSize: number){
    this.pageSize = pageSize;
    this.pageCount = Math.ceil(this.totalItems / this.pageSize);
    this.$pageSize.next(this.pageSize);
    await this.getPage(1);
  }
  async reload(filter: StockFilterParams = null){
    return await this.getPage(this.currentPage, filter);
  }

  async sortStorage(sortParams: Object, filter: StockFilterParams = null) {
    const stockParams = {};
    Object.assign(stockParams, filter);
    Object.assign(stockParams, sortParams);
    return await this.dataSource.getStockCrates(stockParams);
  }

  async sortStorageV2(sortParams: Object, filter: StockFilterParams = null) {
    const stockParams = {};
    Object.assign(stockParams, filter);
    Object.assign(stockParams, sortParams);
    return await this.dataSource.getStockCratesV2(stockParams);
  }

  async getNextPage(filter: StockFilterParams = null){
    if (this.currentPage < this.pageCount){
      return await this.getPage(this.currentPage + 1, filter);
    } else{
      throw Error('???');
    }
  }
  async getPreviousPage(filter: StockFilterParams = null){
    if (this.currentPage > 1){
      return await this.getPage(this.currentPage - 1, filter);
    } else{
      throw Error('???');
    }
  }

  private async getPage(page: number, filter: StockFilterParams = {}){
    this.currentPage = page;
    const stockParams = {
      limit: this.pageSize ,
      offset: (this.currentPage - 1) * this.pageSize
    };
    Object.assign(stockParams, filter);
    const response = await this.dataSource.getStockCrates(stockParams);
    this.totalItems = response.count;
    this.items = response.crates;

    this.pageCount = Math.ceil(this.totalItems / this.pageSize);
    this.$items.next(this.items);
    this.$pageCount.next(this.pageCount);
    this.$totalItems.next(this.totalItems);
    this.$currentPage.next(this.currentPage);
    this.$atLastPage.next(this.pageCount === 0 || this.currentPage === this.pageCount);
    this.$atFirstPage.next(this.currentPage === 1);
  }

  get currentPage$(): Observable<number>{
      return this.$currentPage;
  }
  get atFirstPage$(): Observable<boolean>{
    return this.$atFirstPage;
  }
  get atLastPage$(): Observable<boolean>{
    return this.$atLastPage;
  }
  get totalItems$(): Observable<number>{
    return this.$totalItems;
  }
  get pageCount$(): Observable<number> {
    return this.$pageCount;
  }
  get items$(): Observable<any[]>{
    return this.$items;
  }

}
