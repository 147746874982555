import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroundPositionGroupDataSourceService {

  constructor(private http: HttpClient) { }

  getDetails(id: string) {
    return this.http.get(environment.baseUrl + `/api/v1/admin-desktop/ground/groups/${id}?eager=true`).toPromise<any>();
  }
}
