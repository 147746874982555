<app-side-color-card color="in">
  <div content class="table">

    <div class="empty" *ngIf="positions?.length === 0">
      NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
    </div>

    <div class="header" *ngIf="positions?.length !== 0">
      <div class="position column">Posição</div>
      <div class="rfid column">Rfid</div>
      <div class="sector column">Setor</div>
      <div class="type column">Tipo</div>
      <div class="column">Estado</div>
      <div class="toggle column">Bloqueado</div>
      <div class="button column">Trocar estado</div>
      <div class="button column edit-sector">Editar setor</div>
    </div>

    <div class="item"
         *ngFor="let position of positions; let odd = odd"
         [ngClass]="{'background-opacity': odd}">
      <div class="position column">
        {{ formatPosition(position.x, position.y) }}
      </div>
      <div class="rfid column">
        {{ position.rfId || '---' }}
      </div>
      <div class="sector column">
        {{ position.groupName || '---' }}
      </div>
      <div class="type column">
        {{ translateType(position.type) || '---' }}
      </div>
      <div class="column">
        {{ translateState(position.state) }}
      </div>
      <div class="toggle column">
        <mat-slide-toggle (change)="onToggleChange($event, position)" [checked]="position.state === 'BLOCKED'" [disabled]="position.state === 'MARKED_TO_REPLACE_RFID'"></mat-slide-toggle>
      </div>
      <div class="button column">
        <button mat-raised-button class="primary-2" (click)="markRfidToBeChanged(position)" *ngIf="position.state !== 'MARKED_TO_REPLACE_RFID'">
          marcar para troca de tag rfid
        </button>
        <button mat-raised-button class="alert-background" (click)="markRfidToAsReady(position)" *ngIf="position.state === 'MARKED_TO_REPLACE_RFID'">
          remover marcação para troca de tag rfid
        </button>
      </div>
      <div class="button column edit-sector">
        <button mat-raised-button class="primary-2" (click)="openModal(position)">
          trocar setor
        </button>
      </div>
    </div>
  </div>
</app-side-color-card>

