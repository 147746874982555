import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../../../core/data-sources/layout.service';
import {toExcelFormat} from '../../../helpers/format-position';
import {ActionService, ChangeGroundPositionStateActionState} from '../../../core/services/action.service';
import {MatDialog} from '@angular/material/dialog';
import {EditSectorModalComponent} from '../../../modals/edit-sector-modal/edit-sector-modal.component';
import {first} from 'rxjs/operators';

export enum GroundPositionState {
  READY = 'READY',
  MARKED_TO_REPLACE_RFID = 'MARKED_TO_REPLACE_RFID',
  NO_STATE = 'NO_STATE',
  BLOCKED = 'BLOCKED',
  LAYOUTING = 'LAYOUTING'
}

export enum GroundPositionType {
  DOCK = 'DOCK',
  STREET = 'STREET',
  QUALITY_CONTROL = 'QUALITY_CONTROL',
  STOCK = 'STOCK',
  EXPEDITION = 'EXPEDITION',
  DIVERGENT_STOCK = 'DIVERGENT_STOCK',
  DAMAGES = 'DAMAGES',
  PICKING = 'PICKING',
  PROVISION = 'PROVISION',
  IN = 'IN'
}

@Component({
  selector: 'app-warehouse-map-table',
  templateUrl: './warehouse-map-table.component.html',
  styleUrls: ['./warehouse-map-table.component.scss']
})
export class WarehouseMapTableComponent implements OnInit {

  positions;

  constructor(private layoutService: LayoutService,
              private actionService: ActionService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
   this.loadPositions();
  }

  loadPositions(){
    this.layoutService.getMap().then(res => {
      res.sort((a, b) => {
        return ((100 * a.x) + a.y) - ((100 * b.x) + b.y);
      });
      this.positions = res;
    });
  }

  formatPosition(x, y) {
    return toExcelFormat(x, y);
  }

  markRfidToBeChanged(position) {
    this.actionService.changeGroundPositionState({tagRfid: position.rfId, state: ChangeGroundPositionStateActionState.MARKED_TO_REPLACE_RFID}).then( result =>{
      this.loadPositions();
    });
  }

  markRfidToAsReady(position){
    this.actionService.changeGroundPositionState({tagRfid: position.rfId, state: ChangeGroundPositionStateActionState.READY}).then( result => {
      this.loadPositions();
    });
  }

  onToggleChange(event, position) {
    const action = {
      tagRfid: position.rfId,
      state: ChangeGroundPositionStateActionState.READY
    };
    if (event.checked){
      action.state = ChangeGroundPositionStateActionState.BLOCKED;
    }
    this.actionService.changeGroundPositionState(action).then( result => {
      this.loadPositions();
    });
  }

  openModal(item) {
    const dialogRef = this.dialog.open(EditSectorModalComponent, {
      panelClass: 'confirm-modal',
      data: item
    });
    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res) {
        console.log(res, item);
        const params = {
          rfId: item.rfId,
          type: res.type,
          x: item.x,
          y: item.y,
          groundPositionGroupId: res.groundPositionGroupId
        };
        this.actionService.editGroundPosition(params).then(() => {
          this.loadPositions();
        });
      }
    });
  }

  translateState(state) {
    switch (state) {
      case GroundPositionState.READY:
        return 'Pronto';
      case GroundPositionState.MARKED_TO_REPLACE_RFID:
        return 'Marcado para troca de tag RFID';
      case GroundPositionState.NO_STATE:
        return 'Sem estado';
      case GroundPositionState.BLOCKED:
        return 'Bloqueado';
      case GroundPositionState.LAYOUTING:
        return 'Posicionando';
      default:
        return state || '---';
    }
  }

  translateType(type) {
    switch (type) {
      case GroundPositionType.DOCK:
        return 'Doca';
      case GroundPositionType.STREET:
        return 'Rua';
      case GroundPositionType.QUALITY_CONTROL:
        return 'Controle de qualidade';
      case GroundPositionType.STOCK:
        return 'Estoque';
      case GroundPositionType.EXPEDITION:
        return 'Expedição';
      case GroundPositionType.DIVERGENT_STOCK:
        return 'Estoque Divergente';
      case GroundPositionType.DAMAGES:
        return 'Danos';
      case GroundPositionType.PICKING:
        return 'Separação';
      case GroundPositionType.PROVISION:
        return 'Provisão';
      case GroundPositionType.IN:
        return 'Entrada';
      default:
        return type || '---';
    }
  }
}
