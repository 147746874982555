import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-row-column-details',
  templateUrl: './row-column-details.component.html',
  styleUrls: ['./row-column-details.component.scss']
})
export class RowColumnDetailsComponent implements OnChanges {
  @Input() infoList = [];
  @Input() tableData = [
    {
      rfid: "TESTE_GROUND_POSITION_EXIT_1"
    }
  ]
  datasets = [{
    data: [10, 10],
    backgroundColor: ['#92B531', '#E8EBEF']
  }];
  chartLabels = ['Posições Ocupadas', 'Posições Livres'];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDataset();
  }

  setDataset() {
    if (!this.infoList.length) return;
    let occupiedGroundPositions = 0;
    let freeGroundPositions = 0;

    for (const info of this.infoList) {
      if (info.name.includes('ocupados')) {
        occupiedGroundPositions = info.value;
      }

      if (info.name.includes('livres')) {
        freeGroundPositions = info.value;
      }
    }

    this.datasets = [{
      data: [occupiedGroundPositions, freeGroundPositions],
      backgroundColor: ['#92B531', '#E8EBEF']
    }];
  }

}
