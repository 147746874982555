import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Pallet } from '../../stock-levels-modal.component';

@Component({
  selector: 'app-crate-item',
  templateUrl: './crate-item.component.html',
  styleUrls: ['./crate-item.component.scss']
})
export class CrateItemComponent implements OnInit, OnChanges {

  @Input() pallet: Pallet;

  type: string = '';
  rfid: string = '';
  state: string = '';
  altura: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.pallet && changes.pallet.currentValue){
      const p = changes.pallet.currentValue;
      this.type = p.type; //TODO: parse
      this.rfid = p.tagRfid;
      this.state = p.state; //TODO: parse
      if(p.z >= 0){
        this.altura = `${p.z} cm`;
      } else{
        this.altura = 'indefinida';
      }
    }
  }

}
