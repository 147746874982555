import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { InComponent } from './pages/in/in.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import { NotifierModule } from 'angular-notifier';
import { ChartsModule  } from 'ng2-charts';

import { HeaderComponent } from './shared/header/header.component';
import { ExampleComponent } from './pages/example/example.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { InGroupComponent } from './pages/in/in-group/in-group.component';
import { InGroupBuyOrderItemComponent } from './pages/in/in-group-buy-order-item/in-group-buy-order-item.component';
import { InGroupBuyOrderHeaderComponent } from './pages/in/in-group-buy-order-header/in-group-buy-order-header.component';
import {DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import { DatePickerComponent } from './modals/date-picker/date-picker.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import { InGroupNfeHeaderComponent } from './pages/in/in-group-nfe-header/in-group-nfe-header.component';
import { InGroupNfeItemComponent } from './pages/in/in-group-nfe-item/in-group-nfe-item.component';
import { InGroupStatusFilterCtrlComponent } from './pages/in/in-group-status-filter-ctrl/in-group-status-filter-ctrl.component';
import { InDetailsBuyOrderModalComponent } from './modals/in-details-buy-order-modal/in-details-buy-order-modal.component';
import {MatTabsModule} from '@angular/material/tabs';
import { InDetailsBuyOrderComponent } from './modals/in-details-buy-order-modal/in-details-buy-order/in-details-buy-order.component';
import { InDetailsCommentsComponent } from './modals/in-details-buy-order-modal/in-details-comments/in-details-comments.component';
import { InDetailsNfeModalComponent } from './modals/in-details-nfe-modal/in-details-nfe-modal.component';
import {AuthTokenInterceptor} from './core/interceptors/auth-token.interceptor';
import {SideColorCardComponent} from './shared/side-color-card/side-color-card.component';
import { PrevNextButtonComponent } from './shared/prev-next-button/prev-next-button.component';
import { ScreeningComponent } from './pages/screening/screening.component';
import { InspectionComponent } from './pages/inspection/inspection.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HeaderModalComponent } from './modals/shared/header-modal/header-modal.component';
import { StatusHeaderModalComponent } from './modals/shared/status-header-modal/status-header-modal.component';
import { ProviderContactComponent } from './modals/shared/provider-contact/provider-contact.component';
import { InfoOcOrNfeComponent } from './modals/shared/info-oc-or-nfe/info-oc-or-nfe.component';
import { InDetailsNfeComponent } from './modals/in-details-nfe-modal/in-details-nfe/in-details-nfe.component';
import {RefreshTokenInterceptor} from './core/interceptors/refresh-token.interceptor';
import { BackgroundIconComponent } from './shared/background-icon/background-icon.component';
import { ComparisonNfeAndOcComponent } from './modals/in-details-nfe-modal/comparison-nfe-and-oc/comparison-nfe-and-oc.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SidebarMenuComponent } from './shared/sidebar/sidebar-menu.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InspectionStatusFilterComponent } from './pages/inspection/inspection-status-filter/inspection-status-filter.component';
import { InspectionDetailsProductModalComponent } from './modals/inspection-details-product-modal/inspection-details-product-modal.component';
import { PhysicalInspectionComponent } from './modals/inspection-details-product-modal/physical-inspection/physical-inspection.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { InspectionSidenavComponent } from './pages/inspection/inspection-sidenav/inspection-sidenav.component';
import { StockComponent } from './pages/stock/stock.component';
import { StockInventorySidenavComponent } from './pages/stock/stock-inventory-sidenav/stock-inventory-sidenav.component';
import { OperatorsTableComponent } from './shared/operators-table/operators-table.component';
import { SidenavHeaderComponent } from './shared/sidenav-header/sidenav-header.component';
import { StockFiltersSectionComponent } from './pages/stock/stock-filters-section/stock-filters-section.component';
import { StockRelocateSidenavComponent } from './pages/stock/stock-relocate-sidenav/stock-relocate-sidenav.component';
import {MatBadgeModule} from '@angular/material/badge';
import { StockDetailsModalComponent } from './modals/stock-details-modal/stock-details-modal.component';
import { WarehouseMapComponent } from './pages/warehouse-map/warehouse-map.component';
import { MapViewComponent } from './shared/map-view/map-view.component';
import {WarehouseMapViewComponent} from './pages/warehouse-map/warehouse-map-view/warehouse-map-view.component';
import { WarehouseMapTableComponent } from './pages/warehouse-map/warehouse-map-table/warehouse-map-table.component';
import { NotificationNfeComponent } from './shared/notification-nfe/notification-nfe.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { PendingTaskCardComponent } from './shared/pending-task-card/pending-task-card.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { ScreeningGroupComponent } from './pages/screening/screening-group/screening-group.component';
import { ScreeningGroupHeaderComponent } from './pages/screening/screening-group-header/screening-group-header.component';
import { ScreeningGroupItemComponent } from './pages/screening/screening-group-item/screening-group-item.component';
import {WarehouseMapTaskPositionViewComponent} from './pages/map/warehouse-map-task-position-view/warehouse-map-task-position-view.component';
import {DebugComponent} from './pages/debug/debug.component';
import {MatSliderModule} from '@angular/material/slider';
import { DebugTasksComponent } from './pages/debug-tasks/debug-tasks.component';
import { PickingComponent } from './pages/picking/picking.component';
import { PickingGroupComponent } from './pages/picking/picking-group/picking-group.component';
import { PickingReceivedOutOrderItemComponent} from './pages/picking/picking-received-out-order-item/picking-received-out-order-item.component';
import { PickingGroupSellOrderHeaderComponent } from './pages/picking/picking-group-sell-order-header/picking-group-sell-order-header.component';
import { PickingGroupSellOrderWithPalletItemComponent } from './pages/picking/picking-group-sell-order-with-pallet-item/picking-group-sell-order-with-pallet-item.component';
import { PickingGroupSellOrderWithPalletHeaderComponent } from './pages/picking/picking-group-sell-order-with-pallet-header/picking-group-sell-order-with-pallet-header.component';
import { PickingGroupInSeparationItemComponent } from './pages/picking/picking-group-in-separation-item/picking-group-in-separation-item.component';
import { PickingGroupInSeparationHeaderComponent } from './pages/picking/picking-group-in-separation-header/picking-group-in-separation-header.component';
import { PickingPalletsConfirmModalComponent } from './modals/picking-pallets-confirm-modal/picking-pallets-confirm-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { DispatchComponent } from './pages/dispatch/dispatch.component';
import { DispatchGroupComponent } from './pages/dispatch/dispatch-group/dispatch-group.component';
import { DispatchGroupHeaderComponent } from './pages/dispatch/dispatch-group-header/dispatch-group-header.component';
import { DispatchGroupItemComponent } from './pages/dispatch/dispatch-group-item/dispatch-group-item.component';
import { StockInventoryByTagRfidSidenavComponent } from './pages/stock/stock-inventory-by-tag-rfid-sidenav/stock-inventory-by-tag-rfid-sidenav.component';
import { GroundGroupsComponent } from './pages/ground-groups/ground-groups.component';
import { EditSectorModalComponent } from './modals/edit-sector-modal/edit-sector-modal.component';
import { TasksTableHeaderComponent } from './pages/tasks/tasks-table-header/tasks-table-header.component';
import { TasksTableItemComponent } from './pages/tasks/tasks-table-item/tasks-table-item.component';
import { FakeNfeModalComponent } from './modals/fake-nfe-modal/fake-nfe-modal.component';
import {MapComponent} from './pages/map/map.component';
import { UsersComponent } from './pages/users/users.component';
import { StockPendingExpeditionSidenavComponent } from './pages/stock/stock-pending-expedition-sidenav/stock-pending-expedition-sidenav.component';
import { GroundTypesAggregationComponent } from './pages/map/ground-types-aggregation/ground-types-aggregation.component';
import { WarehouseAggregationComponent } from './pages/map/warehouse-aggregation/warehouse-aggregation.component';
import { DashboardGroupsComponent } from './pages/dashboard/dashboard-groups/dashboard-groups.component';
import { DashboardItemComponent } from './pages/dashboard/dashboard-item/dashboard-item.component';
import { GroundGroupsDetailsComponent } from './pages/ground-groups-details/ground-groups-details.component';
import { AddLevelToGroupModalComponent } from './modals/add-level-to-group-modal/add-level-to-group-modal.component';
import { SetupGroundComponent } from './pages/setup/setup-ground/setup-ground.component';
import { GroundGroupsMapComponent } from './pages/setup/setup-ground/ground-groups-map/ground-groups-map.component';
import { GroundGroupsListComponent } from './pages/setup/setup-ground/ground-groups-list/ground-groups-list.component';
import { GroundGroupFormComponent } from './pages/setup/setup-ground/ground-group-form/ground-group-form.component';
import { SetupTenantComponent } from './pages/setup/setup-tenant/setup-tenant.component';
import { SetupComponent } from './pages/setup/setup.component';
import { SetupStepComponent } from './pages/setup/setup-step/setup-step.component';
import { SetupTagsComponent } from './pages/setup/setup-tags/setup-tags.component';
import { GroundPositionTagMapComponent } from './pages/setup/setup-tags/ground-position-tag-map/ground-position-tag-map.component';
import { SetupLevelsComponent } from './pages/setup/setup-levels/setup-levels.component';
import { LevelPreviewComponent } from './pages/setup/setup-levels/level-preview/level-preview.component';
import { SetupLevelCardComponent } from './pages/setup/setup-levels/setup-level-card/setup-level-card.component';
import { SetupFinishComponent } from './pages/setup/setup-finish/setup-finish.component';
import { AddGroupModalComponent } from './modals/add-group-modal/add-group-modal.component';
import { PalletDetailsComponent } from './modals/stock-details-modal/pallet-details/pallet-details.component';
import { StockLevelsModalComponent } from './modals/stock-levels-model/stock-levels-modal.component';
import { LedgerComponent } from './pages/ledger/ledger.component';
import { CrateListDetailsComponent } from './modals/stock-levels-model/crate-list-details/crate-list-details.component';
import { PositionDetailsComponent } from './modals/stock-levels-model/position-details/position-details.component';
import { CrateItemComponent } from './modals/stock-levels-model/crate-list-details/crate-item/crate-item.component';
import {MatTableModule} from '@angular/material/table';
import { StockItemComponent } from './pages/stock/stock-item/stock-item.component';
import { StockItemWithLevelComponent } from './pages/stock/stock-item-with-level/stock-item-with-level.component';
import { TableColumnComponent } from './shared/table/table-column/table-column.component';
import { StockRelocateSinglePalletSidenavComponent } from './pages/stock/side-nav/stock-relocate-single-pallet-sidenav/stock-relocate-single-pallet-sidenav.component';
import { SidenavFooterComponent } from './shared/sidenav-footer/sidenav-footer.component';
import { SidenavCardComponent } from './shared/sidenav-card/sidenav-card.component';
import { SidenavDestinationComponent } from './shared/sidenav-destination/sidenav-destination.component';
import { PendingTaskWidgetComponent } from './shared/pending-task-widget/pending-task-widget.component';
import { PendingTasksComponent } from './pages/pending-tasks/pending-tasks.component';
import { TableHeaderColumnComponent } from './shared/table/table-header-column/table-header-column.component';
import { PageHeaderComponent } from './shared/page/page-header/page-header.component';
import { HeatMapComponent } from './shared/graphs/heat-map/heat-map.component';
import { ForkliftsComponent } from './pages/forklifts/forklifts.component';
import { ImportFileContentButtonComponent } from './shared/import-file-content-button/import-file-content-button.component';
import { TableHeaderComponent } from './shared/table/table-header/table-header.component';
import { TableItemsComponent } from './shared/table/table-items/table-items.component';
import { TableItemComponent } from './shared/table/table-item/table-item.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { StockHeaderComponent } from './pages/stock/stock-header/stock-header.component';
import { StockHeaderWithLevelComponent } from './pages/stock/stock-header-with-level/stock-header-with-level.component';
import { ManualPickingModalComponent } from './modals/manual-picking-modal/manual-picking-modal.component';
import { SelectPalletDropdownComponent } from './shared/ui/select-pallet-dropdown/select-pallet-dropdown.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { PendingTasksEmptyComponent } from './pages/pending-tasks/pending-tasks-empty/pending-tasks-empty.component';
import { PendingTaskErrorWidgetComponent } from './shared/pending-task-error-widget/pending-task-error-widget.component';
import { PasswordConfirmationModalComponent } from './modals/password-confirmation-modal/password-confirmation-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FullPageLoaderComponent } from './shared/full-page-loader/full-page-loader.component';
import {FakeInOrderModalComponent} from './modals/fake-in-order-modal/fake-in-order-modal.component';
import { AddOrEditUserModalComponent } from './modals/add-or-edit-user-modal/add-or-edit-user-modal.component';
import { NotificationComponent } from './shared/notification/notification.component';
import { MovementDetailsComponent } from './modals/stock-details-modal/movement-details/movement-details.component';
import { OutOrderDetailsModalComponent } from './modals/out-order-details-modal/out-order-details-modal.component';
import { ProductImageTabComponent } from './modals/shared/product-image-tab/product-image-tab.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportsTableItemComponent } from './pages/reports/reports-table-item/reports-table-item.component';
import { ReportsTableHeaderComponent } from './pages/reports/reports-table-header/reports-table-header.component';
import { ReportDetailsModalComponent } from './modals/report-details-modal/report-details-modal.component';
import {
  ReportsTableModalComponent
} from './modals/report-details-modal/reports-table-modal/reports-table-modal.component';
import {
  ReportsTableModalHeaderComponent
} from './modals/report-details-modal/reports-table-modal/reports-table-modal-header/reports-table-modal-header.component';
import {
  ReportsTableModalItemComponent
} from './modals/report-details-modal/reports-table-modal/reports-table-modal-item/reports-table-modal-item.component';
import { ProductPickingComponent } from './pages/product-picking/product-picking.component';
import { ProductPickingTableHeaderComponent } from './pages/product-picking/product-picking-table-header/product-picking-table-header.component';
import { ProductPickingTableItemComponent } from './pages/product-picking/product-picking-table-item/product-picking-table-item.component';
import { ProductPickingDetailsModalComponent } from './modals/product-picking-details-modal/product-picking-details-modal.component';
import { AddProductOnProductPickingSidenavComponent } from './pages/product-picking/add-product-on-product-picking-sidenav/add-product-on-product-picking-sidenav.component';
import { PalletsDetailsComponent } from './modals/product-picking-details-modal/pallets-details/pallets-details.component';
import { UserDetailsModalComponent } from './modals/user-details-modal/user-details-modal.component';
import { RelocateFromPickingPositionSidenavComponent } from './pages/product-picking/relocate-from-picking-position-sidenav/relocate-from-picking-position-sidenav.component';
import { PickingGroupInExecutionItemComponent } from './pages/picking/picking-group-in-execution-item/picking-group-in-execution-item.component';
import { PickingGroupInExecutionHeaderComponent } from './pages/picking/picking-group-in-execution-header/picking-group-in-execution-header.component';
import {PackingStartSidenavComponent} from './pages/picking/packing-start-sidenav/packing-start-sidenav.component';
import { OutOrderPlanModalComponent } from './modals/out-order-plan-modal/out-order-plan-modal.component';
import { PackingOutOrderDetailsComponent } from './modals/packing-out-order-details/packing-out-order-details.component';
import { OutOrderExecutionPlanComponent } from './modals/out-order-plan-modal/out-order-execution-plan/out-order-execution-plan.component';
import { RelocateLoadDispatchSidenavComponent } from './pages/pending-tasks/relocate-load-dispatch-sidenav/relocate-load-dispatch-sidenav.component';
import { RestockPickingSidenavComponent } from './pages/pending-tasks/relocate-empty-crates-at-picking-sidenav/restock-picking-sidenav.component';
import { SetupAdminsComponent } from './pages/setup/setup-admins/setup-admins.component';
import { ProductManagementComponent } from './pages/product-management/product-management.component';
import { AddOrEditProductModalComponent } from './modals/add-or-edit-product-modal/add-or-edit-product-modal.component';
import { ProductManagementFiltersComponent } from './pages/product-management/product-management-filters/product-management-filters.component';
import { ProductManagementTableComponent } from './pages/product-management/product-management-table/product-management-table.component';
import { ProductDetailsModalComponent } from './modals/product-details-modal/product-details-modal.component';
import { PalletReportsTableComponent } from './modals/report-details-modal/pallet-reports-table/pallet-reports-table.component';
import { PalletReportsTableItemComponent } from './modals/report-details-modal/pallet-reports-table/pallet-reports-table-item/pallet-reports-table-item.component';
import { PalletDetailsModalComponent } from './modals/pallet-details-modal/pallet-details-modal.component';
import { MatSortModule } from '@angular/material/sort';
import { DoughnutChartComponent } from './shared/doughnut-chart/doughnut-chart.component';
import { CommonListComponent } from './shared/common-list/common-list.component';
import { RowColumnDetailsComponent } from './modals/stock-levels-model/row-column-details/row-column-details.component';
import {MatMenuModule} from '@angular/material/menu';

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  }
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    switch (style) {
      case 'narrow':
      case 'short':
        return ['Jan', 'FEv', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      case 'long':
      default:
          return ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    }
  }
}

export const MY_FORMATS = {
    display: {
    monthYearLabel: 'MMMM',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InComponent,
    NotFoundComponent,
    HeaderComponent,
    ExampleComponent,
    InGroupComponent,
    InGroupBuyOrderItemComponent,
    InGroupBuyOrderHeaderComponent,
    DatePickerComponent,
    InGroupNfeHeaderComponent,
    InGroupNfeItemComponent,
    InGroupStatusFilterCtrlComponent,
    InDetailsBuyOrderComponent,
    InDetailsBuyOrderComponent,
    InDetailsCommentsComponent,
    InDetailsBuyOrderModalComponent,
    InDetailsNfeModalComponent,
    SideColorCardComponent,
    PrevNextButtonComponent,
    ScreeningComponent,
    InspectionComponent,
    DashboardComponent,
    HeaderModalComponent,
    StatusHeaderModalComponent,
    ProviderContactComponent,
    InfoOcOrNfeComponent,
    InDetailsNfeComponent,
    BackgroundIconComponent,
    ComparisonNfeAndOcComponent,
    SidebarMenuComponent,
    InspectionStatusFilterComponent,
    InspectionDetailsProductModalComponent,
    PhysicalInspectionComponent,
    InspectionSidenavComponent,
    StockComponent,
    StockInventorySidenavComponent,
    OperatorsTableComponent,
    SidenavHeaderComponent,
    StockFiltersSectionComponent,
    StockRelocateSidenavComponent,
    SidebarMenuComponent,
    WarehouseMapComponent,
    MapViewComponent,
    WarehouseMapViewComponent,
    StockDetailsModalComponent,
    WarehouseMapTableComponent,
    NotificationNfeComponent,
    PendingTaskCardComponent,
    TasksComponent,
    ScreeningGroupComponent,
    ScreeningGroupHeaderComponent,
    ScreeningGroupItemComponent,
    DebugComponent,
    WarehouseMapTaskPositionViewComponent,
    DebugTasksComponent,
    PickingComponent,
    PickingGroupComponent,
    PickingReceivedOutOrderItemComponent,
    PickingGroupSellOrderHeaderComponent,
    PickingGroupSellOrderWithPalletItemComponent,
    PickingGroupSellOrderWithPalletHeaderComponent,
    PickingGroupInSeparationItemComponent,
    PickingGroupInSeparationHeaderComponent,
    PickingPalletsConfirmModalComponent,
    ConfirmModalComponent,
    DispatchComponent,
    DispatchGroupComponent,
    StockInventoryByTagRfidSidenavComponent,
    DispatchGroupHeaderComponent,
    DispatchGroupItemComponent,
    GroundGroupsComponent,
    EditSectorModalComponent,
    TasksTableHeaderComponent,
    TasksTableItemComponent,
    FakeNfeModalComponent,
    MapComponent,
    UsersComponent,
    StockPendingExpeditionSidenavComponent,
    GroundTypesAggregationComponent,
    WarehouseAggregationComponent,
    DashboardGroupsComponent,
    DashboardItemComponent,
    GroundGroupsDetailsComponent,
    AddLevelToGroupModalComponent,
    SetupGroundComponent,
    GroundGroupsMapComponent,
    GroundGroupsListComponent,
    GroundGroupFormComponent,
    SetupTenantComponent,
    SetupComponent,
    SetupStepComponent,
    SetupTagsComponent,
    GroundPositionTagMapComponent,
    SetupLevelsComponent,
    LevelPreviewComponent,
    SetupLevelCardComponent,
    SetupFinishComponent,
    AddGroupModalComponent,
    PalletDetailsComponent,
    StockLevelsModalComponent,
    LedgerComponent,
    CrateListDetailsComponent,
    PositionDetailsComponent,
    CrateItemComponent,
    StockItemComponent,
    StockItemWithLevelComponent,
    TableColumnComponent,
    StockRelocateSinglePalletSidenavComponent,
    SidenavFooterComponent,
    SidenavCardComponent,
    SidenavDestinationComponent,
    PendingTaskWidgetComponent,
    PendingTasksComponent,
    TableHeaderColumnComponent,
    PageHeaderComponent,
    HeatMapComponent,
    ForkliftsComponent,
    ImportFileContentButtonComponent,
    TableHeaderComponent,
    TableItemsComponent,
    TableItemComponent,
    StockHeaderComponent,
    StockHeaderWithLevelComponent,
    ManualPickingModalComponent,
    SelectPalletDropdownComponent,
    PendingTasksEmptyComponent,
    PendingTaskErrorWidgetComponent,
    PasswordConfirmationModalComponent,
    FullPageLoaderComponent,
    FakeInOrderModalComponent,
    AddOrEditUserModalComponent,
    NotificationComponent,
    MovementDetailsComponent,
    OutOrderDetailsModalComponent,
    ProductImageTabComponent,
    ReportsComponent,
    ReportsTableItemComponent,
    ReportsTableHeaderComponent,
    ReportDetailsModalComponent,
    ReportsTableModalComponent,
    ReportsTableModalHeaderComponent,
    ReportsTableModalItemComponent,
    ProductPickingComponent,
    ProductPickingTableHeaderComponent,
    ProductPickingTableItemComponent,
    ProductPickingDetailsModalComponent,
    AddProductOnProductPickingSidenavComponent,
    PalletsDetailsComponent,
    RelocateFromPickingPositionSidenavComponent,
    UserDetailsModalComponent,
    PackingStartSidenavComponent,
    PickingGroupInExecutionItemComponent,
    PickingGroupInExecutionHeaderComponent,
    OutOrderPlanModalComponent,
    PackingOutOrderDetailsComponent,
    OutOrderExecutionPlanComponent,
    RelocateLoadDispatchSidenavComponent,
    RestockPickingSidenavComponent,
    SetupAdminsComponent,
    ProductManagementComponent,
    AddOrEditProductModalComponent,
    ProductManagementFiltersComponent,
    ProductManagementTableComponent,
    ProductDetailsModalComponent,
    PalletReportsTableComponent,
    PalletReportsTableItemComponent,
    PalletDetailsModalComponent,
    DoughnutChartComponent,
    CommonListComponent,
    RowColumnDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDialogModule,
    MatSnackBarModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSidenavModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatSliderModule,
    ChartsModule,
    MatProgressBarModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 32
        },
        vertical: {
          position: 'top',
          distance: 100,
          gap: 10
        }
      },
      theme: 'material',
      behaviour: {
        autoHide: false,
        onClick: 'hide',
        showDismissButton: false,
        stacking: 4
      }
    }),
    FormsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDividerModule,
    MatMenuModule
  ],
  providers: [
      {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
      {
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy,
      },
      {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthTokenInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
