import { Injectable } from '@angular/core';
import { ActionDataSourceService, ActionsEnum } from '../data-sources/action-data-source.service';
import { GroundPositionType } from '../../pages/warehouse-map/warehouse-map-table/warehouse-map-table.component';
import { UserLogType, UserRoles } from '../../../models/User';
import { TenantService } from './tenant.service';
import { ExecutionPlan } from 'src/app/pages/picking/picking.component';
import { ModulesEnum } from '../../../models/Modules';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(
    private dataSource: ActionDataSourceService,
    private tenantService: TenantService
    ) {

    }

  updateProductPicture(action: any) {
    return this.dataSource.action(ActionsEnum.UPDATE_PRODUCT_PICTURE, action);
  }

  setDestination(action: SetDestinationAction){
    return this.dataSource.action(ActionsEnum.SET_DESTINATION, action);
  }

  bulkSetDestination(action: BulkSetDestinationAction) {
    return this.dataSource.action(ActionsEnum.BULK_SET_DESTINATION, action);
  }

  setResolution(action: SetResolutionAction) {
    return this.dataSource.action(ActionsEnum.SET_RESOLUTION, action);
  }

  setReceivedResolution(action: SetReceivedResolutionAction) {
    return this.dataSource.action(ActionsEnum.SET_RECEIVED_RESOLUTION, action);
  }

  sendTask(action: SendTaskAction) {
    return this.dataSource.action(ActionsEnum.SEND_TASKS, action);
  }

  sendCriticalTask(action: SendTaskAction) {
    return this.dataSource.action(ActionsEnum.SEND_CRITICAL_TASKS, action);
  }

  bulkSendTask(action: BulkSendTaskAction) {
    return this.dataSource.action(ActionsEnum.BULK_SEND_TASKS, action);
  }

  bulkSendCriticalTask(action: BulkSendTaskAction) {
    return this.dataSource.action(ActionsEnum.BULK_SEND_CRITICAL_TASKS, action);
  }

  changeGroundPositionState(action: ChangeGroundPositionStateAction) {
    return this.dataSource.action(ActionsEnum.CHANGE_GROUND_POSITION_STATE, action);
  }

  inventoryByGroundPositionGroup(action: InventoryByGroundPositionAction) {
    return this.dataSource.action(ActionsEnum.SEND_TASKS, action);
  }

  inventoryByProduct(action: InventoryByProductAction) {
    return this.dataSource.action(ActionsEnum.SEND_TASKS, action);
  }

  sendInventoryTask(action: SendInventoryTask) {
    return this.dataSource.action(ActionsEnum.SEND_TASKS, action);
  }

  reserveExpedition(action: ReserveExpeditionAction) {
    return this.dataSource.action(ActionsEnum.RESERVE_EXPEDITION, action);
  }

  setInvoiceRequested(action: SetInvoiceRequestedAction) {
    return this.dataSource.action(ActionsEnum.SET_INVOICE_REQUESTED, action);
  }

  sendToExpedition(action: SendToExpeditionAction) {
    return this.dataSource.action(ActionsEnum.SEND_TO_EXPEDITION, action);
  }

  inventoryByTagRfid(action: InventoryByTagRfid) {
    return this.dataSource.action(ActionsEnum.SEND_TASKS, action);
  }

  inventoryBySingleTagRfid(action: InventoryBySingleTagRfid) {
    return this.dataSource.action(ActionsEnum.SEND_TASKS, action);
  }

  deleteTask(action: DeleteTasks) {
    return this.dataSource.action(ActionsEnum.DELETE_TASKS, action);
  }

  addGroundGroup(action: AddGroundGroup) {
    return this.dataSource.action(ActionsEnum.ADD_GROUND_GROUP, action);
  }

  editGroundPosition(action: EditGroundPosition) {
    return this.dataSource.action(ActionsEnum.EDIT_GROUND_POSITION, action);
  }

  relocateExpedition(action: RelocateExpeditionAction) {
    return this.dataSource.action(ActionsEnum.SEND_TASKS, action);
  }
  addLevelToGroundGroup(action: AddGroundGroupLevelActionParam) {
    return this.dataSource.action(ActionsEnum.ADD_GROUND_GROUP_LEVEL, action);
  }
  removeLevelToGroundGroup(action: RemoveGroundGroupLevelActionParam) {
    return this.dataSource.action(ActionsEnum.REMOVE_GROUND_GROUP_LEVEL, action);
  }
  setTagsToGroundGroup(action: SetTagsGroundGroupActionParam){
    return this.dataSource.action(ActionsEnum.SET_TAGS_GROUND_GROUP, action);
  }
  removeGroundGroup(action: RemoveGroundGroupParams){
    return this.dataSource.action(ActionsEnum.REMOVE_GROUND_GROUP, action);
  }

  enableTenantLevels(action: EnableTenantLevelsParams){
    return this.dataSource.action(ActionsEnum.ENABLE_TENANT_LEVEL, action).then(res=>{
      this.tenantService.get(true);
      return res;
    });
  }
  enableGroundGroupLevels(action: EnableGroundGroupLevelsParams){
    return this.dataSource.action(ActionsEnum.ENABLE_GROUND_GROUP_LEVEL, action);
  }
  addLevelToTenant(action: AddTenantLevelActionParam){
    return this.dataSource.action(ActionsEnum.ADD_TENANT_LEVEL, action);
  }
  removeLevelFromTenant(action: RemoveTenantLevelActionParam){
    return this.dataSource.action(ActionsEnum.REMOVE_TENANT_LEVEL, action);
  }
  setTenantState(action: SetTenantStateParams){
    return this.dataSource.action(ActionsEnum.SET_TENANT_STATE, action);
  }
  setTenantErp(action: SetTenantErpParams){
    return this.dataSource.action(ActionsEnum.SET_TENANT_ERP, action);
  }
  setTenantErpConfig(action: SetTenantErpDummyConfig|any){
    return this.dataSource.action(ActionsEnum.SET_TENANT_ERP_CONFIG, action);
  }
  setTenantOrientation(action: SetTenantOrientationParams){
    return this.dataSource.action(ActionsEnum.SET_TENANT_ORIENTATION, action);
  }
  setTenantModules(action: SetTenantModules) {
    return this.dataSource.action(ActionsEnum.SET_TENANT_MODULES, action);
  }
  setTenantConfig(action: SetTenantConfig) {
    return this.dataSource.action(ActionsEnum.SET_TENANT_CONFIG, action);
  }
  editUserLogLevel(action: EditUserLogLevelParams){
    return this.dataSource.action(ActionsEnum.EDIT_USER_LOG_LEVEL, action);
  }
  createExpedition(action: CreateExpeditionParams, disableAutoErrorHandler=false){
    return this.dataSource.action(ActionsEnum.CREATE_EXPEDITION, action,{disableAutoErrorHandler});
  }
  removePallet(action: RemovePalletParams, disableAutoErrorHandler= false){
    return this.dataSource.action(ActionsEnum.REMOVE_PALLET, action,{disableAutoErrorHandler});
  }

  consumeShowRoomInvoices(disableAutoErrorHandler= false){
    return this.dataSource.action(ActionsEnum.CONSUME_SHOWROOM_INVOICES, {},{ disableAutoErrorHandler});
  }

  resetShowRoomInvoice(action: ResetShowRoomInvoicesParams, disableAutoErrorHandler= false){
    return this.dataSource.action(ActionsEnum.REMOVE_SHOWROOM_INVOICE, action,{ disableAutoErrorHandler});
  }

  inventoryReview(action: InventoryReviewAction) {
    return this.dataSource.action(action.type, action.data);
  }

  acceptActualPosition(action: ApprovePalletStockPositionAction) {
    return this.dataSource.action(action.type, action.data);
  }

  removeProductFromPickingPosition(action: RemoveProductFromPickingPositionAction) {
    return this.dataSource.action(action.type, action.data);
  }

  setProductToPickingPosition(action: SetProductToPickingPositionAction) {
    return this.dataSource.action(action.type, action.data);
  }

  setExecutionPlan(action: SetExecutionPlanAction, options = {disableAutoErrorHandler: false}) {
    return this.dataSource.action(action.type, action.data, options);
  }
  unsetExecutionPlan(action: UnsetExecutionPlanAction, options = {disableAutoErrorHandler: false}) {
    return this.dataSource.action(action.type, action.data, options);
  }

  runExecutionPlan(action: RunExecutionPlanAction, options = { disableAutoErrorHandler: false }) {
    return this.dataSource.action(action.type, action.data, options);
  }

  startPacking(action: StartPackingAction){
    return this.dataSource.action(action.type, action.data);
  }

  createInternalOutOrder(action: CreateInternalOutOrderAction, options = {disableAutoErrorHandler: false}) {
    return this.dataSource.action(action.type, action.data, options);
  }

  createProduct(action: CreateProductAction, options = {disableAutoErrorHandler: false}) {
    return this.dataSource.action(action.type, action.data, options, ModulesEnum.ADMIN_PRODUCT);
  }

  editProduct(action: EditProductAction, options = {disableAutoErrorHandler: false}) {
    return this.dataSource.action(action.type, action.data, options, ModulesEnum.ADMIN_PRODUCT);
  }

  removeProduct(action: RemoveProductAction, options = {disableAutoErrorHandler: false}) {
    return this.dataSource.action(action.type, action.data, options, ModulesEnum.ADMIN_PRODUCT);
  }

  editUser(action: EditUserAction, options = {disableAutoErrorHandler: false}){
    return this.dataSource.action(ActionsEnum.EDIT_USER, action);
  }

  deleteUser(action: DeleteUserAction, options = {disableAutoErrorHandler: false}) {
    return this.dataSource.action(ActionsEnum.DELETE_USER, action);
  }

  createUser(action: EditUserAction, options = {disableAutoErrorHandler: false}){
    return this.dataSource.action(ActionsEnum.CREATE_USER, action);
  }

  removePalletsFromExitPosition(action: RemoveCratesFromSpecificExitGroundPosition) {
    return this.dataSource.action(action.type, action.data);
  }
}

export interface ResetShowRoomInvoicesParams {
  id: string;
}

export interface RemovePalletParams {
  tagRfid: string;
  password: string;
}
export interface CreateExpeditionParams {
  orderNo: string;
  date: number;
}
export interface EditUserLogLevelParams {
  userId: string;
  logConfig: UserLogType;
}
export interface SetTenantErpParams {
  erp: TenantErp;
}

export interface SetTenantOrientationParams {
  orientation: number;
}

export interface SetTenantModules {
  products: boolean;
}

export interface SetTenantConfig {
  enableHandScannerCrateEdition: boolean;
}

export interface SetTenantErpDummyConfig {
  config: {
    url: string;
  };
}

export enum TenantErp{
  PROTHEUS = 'PROTHEUS',
  KPL= 'KPL',
  DUMMY= 'DUMMY',
  NONE= 'NONE'
}

export enum TenantState{
  OFFLINE= 'OFFLINE',
  SETUP_TENANT_INITIAL_CONFIG= 'SETUP_TENANT_INITIAL_CONFIG',
  SETUP_ADMINS = 'SETUP_ADMINS',
  SETUP_TAGS= 'SETUP_TAGS',
  SETUP_GROUPS= 'SETUP_GROUPS',
  SETUP_LEVELS= 'SETUP_LEVELS',
  SETUP_REVIEW= 'SETUP_REVIEW',
  ONLINE= 'ONLINE'
}
export interface SetTenantStateParams {
  state: TenantState;
}

export interface EnableGroundGroupLevelsParams {
  enabled: boolean;
  groundPositionGroupId: string;
}

export interface EnableTenantLevelsParams {
  enabled: boolean;
}

export interface RemoveGroundGroupParams {
  groundPositionGroupId: string;
}

export interface SetDestinationAction{
  invoiceId: string;
  sku: string;
  destiny: string;
}

export interface BulkSetDestinationAction {
  products: SetDestinationProduct[];
}

export interface SetDestinationProduct {
  invoiceId: string;
  sku: string;
  destiny: DestinationEnum;
}

export enum DestinationEnum {
  STORAGE = 'STORAGE',
  QUALITY = 'QUALITY',
  STOCK_DIVERGENT = 'STOCK_DIVERGENT'
}

export interface SetResolutionAction {
  id: string;
  receiveEnable: boolean;
  correctionIssued: boolean;
}

export interface SetReceivedResolutionAction {
  id: string;
  correctionIssued: boolean;
}

export interface SendTaskAction {
  type: string;
  userIds: string[];
  tagRfid: string;
}

export interface ChangeGroundPositionStateAction {
  tagRfid: string;
  state: ChangeGroundPositionStateActionState;
}

export enum ChangeGroundPositionStateActionState {
  NO_STATE = 'NO_STATE',
  LAYOUTING = 'LAYOUTING',
  MARKED_TO_REPLACE_RFID = 'MARKED_TO_REPLACE_RFID',
  READY = 'READY',
  BLOCKED = 'BLOCKED'
}

export interface BulkSendTaskAction {
  type: string;
  userIds: string[];
  tagsRfid: string[];
}

export interface InventoryByGroundPositionAction {
  type: string;
  userIds: string[];
  taskData: {
    groundPositionGroupId: string[];
  };
}

export interface InventoryByProductAction {
  type: string;
  userIds: string[];
  taskData: {
    sku: string[];
  };
}

export interface SendInventoryTask {
  type?: InventoryTypesEnum;  // optional to handle different types
  userIds: string[];
  taskData?: {
    sku: string[]
  } | {
    itemSetId: string[]
  } | {
    itemPackage: string[]
  } | {
    type: string[]
  }; // optional to handle different types
}

export enum InventoryTypesEnum {
  INVENTORY_BY_ITEM_SKU = 'INVENTORY_BY_ITEM_SKU',
  INVENTORY_BY_ITEM_SET = 'INVENTORY_BY_ITEM_SET',
  INVENTORY_BY_ITEM_TYPE = 'INVENTORY_BY_ITEM_TYPE',
  INVENTORY_BY_ITEM_PACKAGE = 'INVENTORY_BY_ITEM_PACKAGE',
  INVENTORY_BY_TAG_RFID = 'INVENTORY_BY_TAG_RFID',
  INVENTORY_BY_GROUND_POSITION_GROUP = 'INVENTORY_BY_GROUND_POSITION_GROUP',
  PRODUCT_INVENTORY_BY_CRATE_TAG_RFID = 'PRODUCT_INVENTORY_BY_CRATE_TAG_RFID',
  INVENTORY_BY_PRODUCT = 'INVENTORY_BY_PRODUCT'
}

export interface ReserveExpeditionAction {
  outOrderNumber: string;
  cratesIds: string[];
}

export interface SetInvoiceRequestedAction {
  outOrderNumber: string;
  invoiceRequested: boolean;
}

export interface SendToExpeditionAction {
  outOrderNumber: string;
  userIds: string[];
  destiny: {
    x: number;
    y: number;
  }
}

export interface InventoryByTagRfid {
  type: string;
  userIds: string[];
  taskData: {
    tagRfid: string[];
  };
}

export interface InventoryBySingleTagRfid {
  type: string;
  userIds: string[];
  taskData: {
    tagRfid: string;
  };
}

export interface DeleteTasks {
  ids: number[];
}

export interface AddGroundGroup {
  name: string;
  type: GroundPositionType;
}

export interface EditGroundPosition {
  rfId: string;
  type: GroundPositionType;
  x: number;
  y: number;
  groundPositionGroupId: string;
}
export interface AddGroundGroupLevelActionParam{
  groundPositionGroupId: string;
  name: string;
  order: number;
  start: number;
  end: number;
  reference: number;
}

export interface AddTenantLevelActionParam{
  name: string;
  order: number;
  start: number;
  end: number;
  reference: number;
}
export interface RemoveTenantLevelActionParam{
  id: string;
}

export interface RemoveGroundGroupLevelActionParam{
  id: string;
}

export interface SetTagsGroundGroupActionParam{
  groundPositionGroupId: string;
  rfidTags: string[];
  overwrite?: boolean;
}

export interface RelocateExpeditionAction {
  type: string;
  userIds: string[];
  tagRfid: string;
  taskData: {
    outOrderNumber: string;
    destiny: {
      x: number;
      y: number;
    };
  };
}

export interface InventoryReviewAction {
  type: ActionsEnum.APPROVE_INVENTORY | ActionsEnum.REJECT_INVENTORY;
  data: {
    inventoryId: string;
  };
}

export interface RemoveProductFromPickingPositionAction {
  type: ActionsEnum.REMOVE_PRODUCT_FROM_PICKING_POSITION;
  data: {
    userIds: string[];
    pickingPosition: {
      x: number,
      y: number,
    };
    destiny: {
      x: number,
      y: number
    };
  };
}

export interface SetProductToPickingPositionAction {
  type: ActionsEnum.SET_PRODUCT_TO_PICKING_POSITION;
  data: {
    groundPosition: {
      x: number;
      y: number;
    };
    sku: string;
    minQuantity: number;
    maxQuantity: number;
    userIds?: string[];
    tagRfid: string;
  };
}

export interface ApprovePalletStockPositionAction {
  type: ActionsEnum.APPROVE_PALLET_STOCK_POSITION;
  data: {
    tagRfid: string;
  };
}

export interface SetExecutionPlanAction {
  type: ActionsEnum.SET_EXECUTION_PLAN;
  data: {
    outOrderId: string;
    executionPlan: ExecutionPlan;
  };
}

export interface RunExecutionPlanAction {
  type: ActionsEnum.RUN_EXECUTION_PLAN;
  data: {
    outOrderId: string;
  };
}

export interface UnsetExecutionPlanAction {
  type: ActionsEnum.UNSET_EXECUTION_PLAN;
  data: {
    outOrderId: string;
  };
}

export interface StartPackingAction {
  type: ActionsEnum.START_PACKING;
  data: {
    outOrderId: string,
    destiny: {
      x: number,
      y: number
    },
    pickingSteps?: {
      userIds: string[],
      steps: {x: number, y: number, sku: string, quantity: number}[]
    },
    selectedPallets?: {
      userIds: string [],
      palletIds: string[]
    }
  };
}

export interface CreateInternalOutOrderAction {
  type: ActionsEnum.CREATE_INTERNAL_OUT_ORDER;
  data: {
    orderNo: string;
    cratesIds: string[];
  };
}

export interface CreateProductAction {
  type: ActionsEnum.CREATE_PRODUCT;
  data: {
    sku: string;
    name: string;
    description?: string;
    weight?: number;
    height?: number;
    width?: number;
    depth?: number;
    data?: {};
  };
}

export interface EditProductAction {
  type: ActionsEnum.EDIT_PRODUCT;
  data: {
    sku: string;
    name?: string;
    description?: string;
    weight?: number;
    height?: number;
    width?: number;
    depth?: number;
    data?: {};
  };
}

export interface RemoveProductAction {
  type: ActionsEnum.REMOVE_PRODUCT;
  data: {
    sku: string;
  };
}

export interface EditUserAction {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  type?: UserRoles;
}

export interface DeleteUserAction {
  id: string;
}

export interface CreateUserAction {
  firstName: string;
  lastName: string;
  email: string;
  type: UserRoles;
  username: string;
  password: string;
}

export interface RemoveCratesFromSpecificExitGroundPosition {
  type: ActionsEnum.REMOVE_CRATES_FROM_SPECIFIC_EXIT_GROUND_POSITION,
  data: {
    rfids: string[]
  }
}
