<div class="wrapper">
  <div class="upper-content">
    <div class="first-column">
      <app-info-oc-or-nfe label="Tag RFID" [value]="crateDetails.crate.tagRfid"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Estado (crateState)" [value]="crateDetails.crate.state"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Posição" [value]="excelPosition"></app-info-oc-or-nfe>
    </div>
    <div class="second-column">
      <app-info-oc-or-nfe label="X" [value]="crateDetails.crate.x"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Y" [value]="crateDetails.crate.y"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Z" [value]="crateDetails.crate.z"></app-info-oc-or-nfe>
      <app-info-oc-or-nfe label="Nível" [value]="level"></app-info-oc-or-nfe>
    </div>
    <div class="third-column">
      <button mat-raised-button (click)="relocateClick()" [disabled]="cantRelocate()">
        Realocar
      </button>
<!--      <button class="remove-button" mat-raised-button (click)="removeClick()">-->
<!--        remover-->
<!--      </button>-->
    </div>
  </div>
  <div class="lower-content">
    <table mat-table [dataSource]="dataSource" class="table">

      <ng-container matColumnDef="name">
        <th mat-header-cell class="header" *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="packSize">
        <th mat-header-cell class="header" *matHeaderCellDef>Tamanho do Pacote</th>
        <td mat-cell *matCellDef="let element">{{element.packSize}}</td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell class="header" *matHeaderCellDef>Quantidade</th>
        <td mat-cell *matCellDef="let element">{{element.quantity}}</td>
      </ng-container>

      <ng-container matColumnDef="sku">
        <th mat-header-cell class="header" *matHeaderCellDef>Código SKU</th>
        <td mat-cell *matCellDef="let element">{{element.sku}}</td>
      </ng-container>

      <tr mat-header-row class="header-container" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="item-container" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="!dataSource.length" class="no-item">
      Não existem produtos neste pallet
    </div>
  </div>
</div>
