import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {toExcelFormat} from '../../../helpers/format-position';
import {findCrateLevel} from '../../../helpers/find-crate-level';

interface CrateItem {
  name: string;
  packSize: number;
  quantity: number;
  sku: string;
}

@Component({
  selector: 'app-pallet-details',
  templateUrl: './pallet-details.component.html',
  styleUrls: ['./pallet-details.component.scss']
})
export class PalletDetailsComponent implements OnInit {

  @Input() crateDetails;
  @Output() relocateClicked = new EventEmitter();
  @Output() removeClicked = new EventEmitter();

  displayedColumns = ['name', 'packSize', 'quantity', 'sku'];
  dataSource: CrateItem[] = [];

  excelPosition;
  level;

  constructor() {  }

  ngOnInit(): void {
    this.dataSource = this.crateDetails.crate.skuGroups;
    this.excelPosition = toExcelFormat(this.crateDetails.crate.x, this.crateDetails.crate.y);
    this.level = findCrateLevel(this.crateDetails.crate.z, this.crateDetails.levels);
  }
  cantRelocate(){
    const {state} = this.crateDetails.crate;
    return !(state === 'WRONG_POSITION' || state === 'STORED' || state === 'MISPLACED_DIVERGENT' || state === 'MISPLACED')
      || this.crateDetails.position.type === 'PICKING';
  }

  relocateClick(){
    this.relocateClicked.emit();
  }
  removeClick(){
    this.removeClicked.emit();
  }


}
