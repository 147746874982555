    <app-table-header-column name="date" size="small">
      <span content mat-sort-header="createdAt">Entrada</span>
    </app-table-header-column>

    <app-table-header-column name="date" size="small">
      <span content mat-sort-header="updatedAt">Última movimentação</span>
    </app-table-header-column>

    <app-table-header-column name="sector" size="small">
      <span content>Setor</span>
    </app-table-header-column>

    <app-table-header-column name="address" size="smallest">
      <span content mat-sort-header="position">Endereço</span>
    </app-table-header-column>

    <app-table-header-column name="height" size="smallest">
      <span content mat-sort-header="z">Altura</span>
    </app-table-header-column>

    <app-table-header-column name="product-code" size="small">
      <span content>Código de produto</span>
    </app-table-header-column>

    <app-table-header-column name="description" size="medium">
      <span content>Descrição</span>
    </app-table-header-column>

    <app-table-header-column name="quantity" size="smallest">
      <span content>Qtd</span>
    </app-table-header-column>

    <app-table-header-column name="state" size="small">
      <span content mat-sort-header="state">Estado</span>
    </app-table-header-column>

    <app-table-header-column name="messages" size="smallest">
      <span content>Comentários</span>
    </app-table-header-column>
