import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { LayoutService } from '../../../core/data-sources/layout.service';
import { Subscription } from 'rxjs';
import { setOccupationChartDataset } from 'src/app/helpers/chart-helper';

@Component({
  selector: 'app-warehouse-aggregation',
  templateUrl: './warehouse-aggregation.component.html',
  styleUrls: ['./warehouse-aggregation.component.scss']
})
export class WarehouseAggregationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() map;
  occupationList = [];
  rfidTags;
  now;
  total;

  public datasets = [{
    data: [10, 10],
    backgroundColor: ['#92B531', '#E8EBEF']
  }];
  cratesCountSubscription: Subscription;
  chartLabels = ['Posições Ocupadas', 'Posições Livres'];
  window = window;

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  constructor(private elementRef: ElementRef,
              private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.now = moment().format("DD-MM-yyyy");
    this.cratesCountSubscription = this.layoutService.cratesCount$.subscribe(count => {
      this.rfidTags = count;
      if (this.rfidTags !== undefined && this.map){
        this.aggregation(this.map, this.rfidTags);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.map?.currentValue){
      this.map = changes.map.currentValue;
    }
    if(this.rfidTags !== undefined && this.map){
      this.aggregation(this.map, this.rfidTags);

    }
  }

  ngOnDestroy(): void {
    this.cratesCountSubscription.unsubscribe();
  }

  aggregation(maps, rfidtags) {
    // FIXME: display this things when the stockCount = 0
    this.setProperties(maps, rfidtags);
  }

  async setProperties(maps, rfidTags) {
    await this.layoutService.getPositionCount().then(list => {
      this.occupationList = [];
      const groundGroupsSet = new Set();
      for (const m of maps) {
        groundGroupsSet.add(m.groupId);
      }
      this.occupationList.push({
        name: 'Tags RFIDS', value: rfidTags
      });
      this.occupationList.push({
        name: 'Setores', value: groundGroupsSet.size
      });
      list.forEach(item => {
        this.occupationList.push(item);
        this.datasets[0].data = setOccupationChartDataset(this.occupationList);
        this.total = this.datasets[0].data.reduce((acc, curr) => acc + curr, 0);
      });
    });
  }

}
