export enum CrateStateEnum {
  STORED = 'STORED',
  STORED_DIVERGENT = 'STORED_DIVERGENT',
  WRONG_POSITION = 'WRONG_POSITION',
  RECEIVED = 'RECEIVED',
  AWAITING_STORAGE = 'AWAITING_STORAGE',
  AWAITING_PICKING = 'AWAITING_PICKING',
  AWAITING_MOVING = 'AWAITING_MOVING',
  MOVING = 'MOVING',
  MISPLACED = 'MISPLACED',
  MISPLACED_DIVERGENT = 'MISPLACED_DIVERGENT',
  MISSING = 'MISSING',
  RESERVED = 'RESERVED',
  FOUND_MISPLACED = 'FOUND_MISPLACED',
  FOUND = 'FOUND'
}

export function translateState(state: CrateStateEnum) {
  switch (state) {
    case CrateStateEnum.STORED:
      return 'Armazenado';
    case CrateStateEnum.STORED_DIVERGENT:
      return 'Armazenado e divergente';
    case CrateStateEnum.WRONG_POSITION:
      return 'Posição errada';
    case CrateStateEnum.RECEIVED:
      return 'Recebido';
    case CrateStateEnum.AWAITING_STORAGE:
      return 'Aguardando armazenamento';
    case CrateStateEnum.AWAITING_PICKING:
      return 'Aguardando separação';
    case CrateStateEnum.AWAITING_MOVING:
      return 'Aguardando movimentação';
    case CrateStateEnum.MOVING:
      return 'Movimentando';
    case CrateStateEnum.MISPLACED:
      return 'Deslocado';
    case CrateStateEnum.MISPLACED_DIVERGENT:
      return 'Deslocado e divergente';
    case CrateStateEnum.MISSING:
      return 'Perdido';
    case CrateStateEnum.RESERVED:
      return 'Reservado';
    case CrateStateEnum.FOUND_MISPLACED:
      return 'Encontrado em lugar indevido';
    case CrateStateEnum.FOUND:
      return 'Encontrado';
    default:
      return '---';
  }
}

export interface ICrateMap {
  id: string;
  x: number;
  y: number;
  skuGroups: {
    sku: string;
    name: string;
  }[];
}

export interface ICrate {
  description: string;
  id: string;
  quantity: number;
  sku: string;
  tagRfid: string;
}
