import { LayoutService } from './../../core/data-sources/layout.service';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GroundPositionService} from '../../core/services/ground-position.service';
import {columnToNumber, toExcelFormat} from '../../helpers/format-position';

interface StockLevelsModalInterface {
  rfId: string;
  type: 'row' | 'column' | 'position';
  position: string;
}
export interface GroupPosition {
  name: string;
  type: string;
  levelsEnabled: boolean;
}
export interface Position {
  rfId: string;
  type: string;
  coords: string;
}
export interface Level {
  id: string;
  name: string;
  order: number;
  reference: number;
  start: number;
  end: number;
}
export interface Pallet {
  id: string;
  tagRfid: string;
  skuGroups: {
    packSize: number;
    quantity: number;
    sku: string;
    name: string;
  }[];
  state: string;
  type: string;
  x: number;
  y: number;
  z: number;
  position?: string;
}

@Component({
  selector: 'app-stock-levels-model',
  templateUrl: './stock-levels-modal.component.html',
  styleUrls: ['./stock-levels-modal.component.scss']
})
export class StockLevelsModalComponent implements OnInit {

  title = '';
  group: GroupPosition = {
    name: '',
    type: '',
    levelsEnabled: false
  };
  position: Position = {
    coords: '',
    rfId: '',
    type: '',
  };
  levels: Level[] = [];
  pallets: Pallet[] = [];
  tabLabel = '';
  occupationList = [];
  rowColumnPositions = [];
  rowColumnCoords = '';

  constructor(public dialogRef: MatDialogRef<StockLevelsModalComponent>,
              private groundPosition: GroundPositionService,
              private layoutService: LayoutService,
              @Inject(MAT_DIALOG_DATA) public data: StockLevelsModalInterface) { }

  ngOnInit(): void {
    const { rfId } = this.data;
    this.setTabLabelName();
    this.setTitle(null, null, this.data.position);
    if (this.data.type === 'position') {
      this.groundPosition.getDetails(rfId).then(res => {
        const { position } = res;
        const { group } = position;
        this.setTitle(res.position.x, res.position.y);
        this.position.rfId = position.rfId;
        this.position.type = position.type;
        this.position.coords = `${toExcelFormat(position.x, position.y)} (x:${position.x} y:${position.y})`;

        if (group) {
          this.group.name = group.name;
          this.group.type = group.type;
          this.group.levelsEnabled = group.levelsEnabled;
        }
        const levels = res?.levels.sort((a, b) => b.order - a.order) ?? [];
        this.levels = levels;
        this.pallets = res?.pallets.filter(p => p.type !== 'ABSTRACT') ?? [];
        this.getCellPositionCount(position.x, position.y);
      });
    } else {
      this.getRowOrColumnPositionCount(this.data.type);
    }
  }

  async getRowOrColumnPositionCount(type: 'row' | 'column') {
    if (type === 'row') {
      const y = Number(this.data.position) - 1;
      this.rowColumnCoords = `y: ${y}`;
      this.getRowOrColumnData(null, y);
    } else {
      const x = columnToNumber(this.data.position);
      this.rowColumnCoords = `x: ${x}`;
      this.getRowOrColumnData(x, null);
    }
  }

  async getRowOrColumnData(x: number | null, y: number | null) {
    await this.layoutService.getPositionCount(x, y).then(list => {
      this.occupationList = list;
    });
    if (this.occupationList.length) {
      await this.groundPosition.getGroundPositions(x, y).then(res => {
        this.pallets = this.setRowOrColumnsProperties(res, 'pallets');
        this.rowColumnPositions = this.setRowOrColumnsProperties(res, 'position');
      });
    }
  }

  async getCellPositionCount(x: number, y: number) {
    await this.layoutService.getPositionCount(x, y).then(list => {
      this.occupationList = list;
    });
  }

  handleCloseModalEvent(event: boolean) {
    if (event) {
      this.dialogRef.close();
    }
  }

  setRowOrColumnsProperties(arr, propertyName: 'levels' | 'pallets' | 'position') {
    return arr.reduce((acc, obj) => {
      return acc.concat(obj[propertyName]);
    }, []);
  }

  setTabLabelName() {
    switch (this.data.type) {
      case 'column':
        this.tabLabel = 'Detalhes da Coluna';
        break;
      case 'row':
        this.tabLabel = 'Detalhes da Linha';
        break;
      default:
        this.tabLabel = 'Detalhes da Posição';
        break;
    }
  }

  setTitle(x?: number, y?: number, name?: string) {
    switch (this.data.type) {
      case 'column':
        this.title = `Coluna ${name}`;
        break;
      case 'row':
        this.title = `Linha ${name}`;
        break;
      default:
        this.title = toExcelFormat(x, y);
        break;
    }
  }

}
