<app-header-modal [color]="'secondary-2'" title="{{title}}">
</app-header-modal>
<div class="content">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="{{tabLabel}}" *ngIf="data.type === 'position'">
      <app-position-details
        [levels]="levels"
        [group]="group"
        [position]="position"
        [pallets]="pallets"
        [occupationList]="occupationList"
        (closeStockLevelsModal)="handleCloseModalEvent($event)">
      </app-position-details>
    </mat-tab>
    <mat-tab label="{{tabLabel}}" *ngIf="data.type !== 'position' ">
      <app-row-column-details
        [occupationList]="occupationList"
        [pallets]="pallets"
        [coords]="rowColumnCoords"
        [positions]="rowColumnPositions"
        >
      </app-row-column-details>
    </mat-tab>
    <mat-tab label="Pallets ({{pallets.length}})">
      <app-crate-list-details [pallets]="pallets"></app-crate-list-details>
    </mat-tab>
  </mat-tab-group>
</div>
