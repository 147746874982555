<div class="details">
  <div class="row warning" *ngIf="pallet.position === 'SECTION'">
    <i class="uil uil-exclamation-triangle"></i>
    <p>
      Esta é a posição no momento da leitura do recebimento deste pallet. Alterações da posição podem ter sido
      realizadas no processo de descarregamento.
    </p>
  </div>

  <div class="row">
    <app-info-oc-or-nfe label="rfId" [value]="rfid"></app-info-oc-or-nfe>
  </div>
  <div class="row">
    <app-info-oc-or-nfe label="altura" [value]="altura"></app-info-oc-or-nfe>
    <app-info-oc-or-nfe label="type" [value]="type"></app-info-oc-or-nfe>
  <app-info-oc-or-nfe label="state" [value]="state"></app-info-oc-or-nfe>
  </div>
</div>




<div class="products">
  <div class="table">
    <div class="header">
      <div class="sku column">
        sku
      </div>
      <div class="name column">
        name
      </div>
      <div class="quantity column">
        quantidade
      </div>
    </div>

    <div class="item" *ngFor="let product of pallet.skuGroups">
      <div class="sku column">
        {{ product.sku }}
      </div>
      <div class="name column">
        {{ product.name }}
      </div>
      <div class="quantity column">
        {{ product.packSize * product.quantity }}
      </div>
    </div>
  </div>
</div>
