<div class="task-request-section">
  <div class="body">
    <app-sidenav-header title="Solicitação de tarefa" (closeEvent)="close()"></app-sidenav-header>

    <form [formGroup]="inventoryForm" class="new-inventory-task-config">
      <div class="card-header">Configurar nova tarefa de inventário</div>
      <div class="card-body" [ngClass]="{'height-transition': isProductToggle || isSectorToggle || isPalletToggle}">
        <p>Inventariar por:</p>
        <div class="buttons">
          <button mat-raised-button class="secondary-2"
                  (click)="toggleOption('PRODUCT')"
                  *ngIf="isProductToggle">Produto</button>
          <button mat-stroked-button class="secondary-2"
                  (click)="toggleOption('PRODUCT')"
                  *ngIf="!isProductToggle">Produto</button>
          <button mat-raised-button class="secondary-2"
                  (click)="toggleOption('SECTOR')"
                  *ngIf="isSectorToggle">Setor</button>
          <button mat-stroked-button class="secondary-2"
                  (click)="toggleOption('SECTOR')"
                  *ngIf="!isSectorToggle">Setor</button>
          <button mat-raised-button class="secondary-2"
                  (click)="toggleOption('PALLET')"
                  *ngIf="isPalletToggle">Conteúdo por Pallet</button>
          <button mat-stroked-button class="secondary-2"
                  (click)="toggleOption('PALLET')"
                  *ngIf="!isPalletToggle">Conteúdo por Pallet</button>
        </div>

        <div class="product-input" *ngIf="isProductToggle">
          <p>Digite o que deseja inventariar:</p>
          <mat-form-field>
            <input matInput
                   placeholder="SKU ou a descrição do produto"
                   formControlName="product"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayedString">
              <mat-option *ngFor="let product of products" [value]="product">{{ product.name + ' - ' + product.sku }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="inventory-sector" *ngIf="isSectorToggle">
          <p>Selecione o setor que deseja inventariar:</p>
          <mat-form-field>
            <mat-label>Setor</mat-label>
            <mat-select multiple [(value)]="sectorValue" (selectionChange)="onChangeSector($event)" formControlName="sector">
              <mat-option *ngFor="let option of sectorOptions" [value]="option.groupId">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="product-input" *ngIf="isPalletToggle">
          <p>Selecione o pallet que deseja inventariar:</p>
          <mat-form-field>
            <input matInput
                   placeholder="Pesquise pela Tag RFID"
                   formControlName="pallet"
                   [matAutocomplete]="palletAutocomplete">
            <mat-autocomplete #palletAutocomplete="matAutocomplete" [displayWith]="setDisplayedPalletName">
              <mat-option *ngFor="let pallet of palletOptions" [value]="pallet">
                Tag RFID: {{pallet.tagRfid}} - Posição: {{toExcelFormat(pallet.x, pallet.y)}} - Altura: {{ pallet.z >= 0 ? centimetersToMeters(pallet.z) + ' metros' : 'Conflitante' }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </form>

<!--    <mat-checkbox (change)="onCriticalChange($event.checked)"-->
<!--                  [checked]="isCritical">Tarefa crítica</mat-checkbox>-->

    <p class="error" *ngIf="sectorsWithoutProducts.length">
      O(s) setor(es)
      <span *ngFor="let sector of sectorsWithoutProducts; let last = last">&nbsp;{{ last ? sector.name : sector.name + ',' }}&nbsp;</span>
      não possuem produtos para realizar a ação.
    </p>

    <p class="error" *ngIf="palletWithoutProduct">O produto
      <span>{{ palletWithoutProduct.name + ' - ' + palletWithoutProduct.sku }}</span> não foi encontrado no armazém.
    </p>

    <p class="error" *ngIf="selectOperatorError">Selecione um ou mais operadores para atribuir a tarefa.</p>

    <app-operators-table device="HANDSCANNER"
                         [usersHighlighted]="usersHighlighted"
                         (usersHighlightedEvent)="setUsers($event)"
                         [openedStart]="drawer.openedStart"
                         [isForklift]="false"
                         [isUserOnline]="true">
                      </app-operators-table>
  </div>

  <div class="footer">
    <button mat-stroked-button class="gray-2" (click)="close()">CANCELAR</button>
    <button mat-raised-button class="primary-1" (click)="submit()" [disabled]="isSectorToggle && !sectorValue?.length">OK</button>
  </div>
</div>
