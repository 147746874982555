import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-crate-list-details',
  templateUrl: './crate-list-details.component.html',
  styleUrls: ['./crate-list-details.component.scss']
})
export class CrateListDetailsComponent implements OnInit, OnChanges {

  @Input() pallets = [];
  @Input() levels = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
