<div class="row">
  <section class="left-side">
    <div class="position">
      <app-info-oc-or-nfe label="Placeholder" [value]="'placeholder'"></app-info-oc-or-nfe>
    </div>
  </section>
  <mat-divider vertical></mat-divider>
  <div class="right-side">
    <app-doughnut-chart [datasets]="datasets" [labels]="chartLabels"></app-doughnut-chart>
    <app-common-list [list]="infoList"></app-common-list>
  </div>
</div>
<div class="table">
  <div class="header">
    <div class="column">
      RFID
    </div>
    <div class="column">
      Posição
    </div>
    <div class="column">
      Tipo do chão
    </div>
    <div class="column">
      Grupo
    </div>
    <div class="column">
      Tipo do grupo
    </div>
  </div>

  <div class="item" *ngFor="let data of tableData">
    <div class="column">
      {{ data.rfid || '---' }}
    </div>
    <div class="column">
      {{ data.order || '---'}}
    </div>
    <div class="column">
      {{ data.start || '---'}} cm
    </div>
    <div class="column">
      {{ data.reference || '---'}} cm
    </div>
    <div class="column">
      {{ data.end || '---'}} cm
    </div>
  </div>
</div>