import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroundPositionService {

  constructor(private http: HttpClient) { }

  getDetails(rfId: string) {
    const endpoint = `${environment.baseUrl}/api/v1/admin-desktop/ground-position/${rfId}`;
    return this.http.get(endpoint).toPromise<any>();
  }
}
